import axios from 'axios';
// const axios = require('axios');

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.

const customAxios = axios.create({
	// baseURL: `https://example.com/api`,
	// timeout: 10000, 
	// headers: { 'api-key': 'eyJz-CI6Ikp-4pWY-lhdCI6' }
});

// Step-2: Create request, response & error handlers
const requestHandler = (request:any) => {
	const accessToken = localStorage.getItem('accessToken') || undefined

	let url:string  = '';
	if (document.location.host.indexOf('localhost')>=0) url = `${process.env.REACT_APP_API_URL_DEV}`
	else if (document.location.host.indexOf('192.168')>=0) url = `${process.env.REACT_APP_API_URL_LOCAL_PROD}`
	else if (document.location.protocol.indexOf('https')>=0) url = `${process.env.REACT_APP_API_URL}`
	else url = `${process.env.REACT_APP_API_URL}`

	request.baseURL = url
	
	if (accessToken) request.headers.Authorization = `Bearer ${accessToken}`

	return request;
};

const responseHandler = (response:any) => {
	if (response.status === 401) {
		window.location.href = '/login';
	}

	return response;
};

const errorHandler = (error:any) => {
	return Promise.reject(error);
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
customAxios.interceptors.request.use(
	(request) => requestHandler(request),
	(error) => errorHandler(error)
);

customAxios.interceptors.response.use(
	(response) => responseHandler(response),
	(error) => errorHandler(error)
);


// Step-4: Export the newly created Axios instance to be used in different locations.
export default customAxios;