import { useForm } from 'react-hook-form';
// import { useLocation, useNavigate } from "react-router-dom";
import { setUserRole, useAuth } from "../../utils/Auth";

export const PilihRole = ( props:any ) => {
  const { user, accessToken, role, divisi, onLogin }:any = useAuth();
  // const location = useLocation();
  // const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  const onSubmit = handleSubmit((data)  => {
    let newRole:any = role
    newRole.selected = true
    if (Number(data.role) === 2) {
      newRole.nama = "Peserta Iknow"
      newRole.id = 9
      setUserRole(newRole)
    }
    onLogin({ user, token: accessToken, role: newRole, divisi });
    // navigate('/', { state: {from: location} });
  });

  const { onLogout } = useAuth();

  const handleLogout = () => {
    onLogout()
  }

  return (
    <div>
      <div className="mx-5 my-2" style={{fontFamily: 'Arial', border: '1px solid #7ba4db'}}>
        <div style={{textAlign: 'center', background: '#7ba4db', color: '#FFF', padding: '6px 0 6px 0'}}>Silahkan pilih role aplikasi</div>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <form onSubmit={onSubmit}>
              <div className="form-group mx-4 my-3">
                <div style={{padding: '6px', borderBottom: '1px solid #004287', opacity: 1}}>
                  <input type='radio' {...register("role", { required: true })} value='1' defaultChecked/>
                  <label className="form-check-label mx-2">Admin</label>
                </div>
                <div style={{padding: '6px', borderBottom: '1px solid #004287', opacity: 1}}>
                  <input type='radio' {...register("role", { required: true })} value='2'/>
                  <label className="form-check-label mx-2">Peserta</label>
                </div>
              </div>
              <div className="text-center pb-3">
                <button className="btn text-white" type="submit" style={{padding: '14px 54px', backgroundColor: '#004287', letterSpacing: '5px'}}>
                  PILIH 
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="mx-5">
        <button type="button" className="btn btn-link p-0" onClick={handleLogout}><i className='fa fa-arrow-left'></i> Kembali ke Halaman Login</button>
      </div>
    </div>
  );
}
