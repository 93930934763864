import { lazy } from 'react';

// PAGE
const Beranda = lazy(() => import('../pages/beranda/Beranda').then(({ Beranda }) => ({ default: Beranda })))
const Diklat = lazy(() => import('../pages/diklat/Diklat').then(({ Diklat }) => ({ default: Diklat })))
const DiklatDetail = lazy(() => import('../pages/diklat_detail/DiklatDetail').then(({ DiklatDetail }) => ({ default: DiklatDetail })))
const Agenda = lazy(() => import('../pages/agenda/Agenda').then(({ Agenda }) => ({ default: Agenda })))
const Pengetahuan = lazy(() => import('../pages/pengetahuan/Pengetahuan').then(({ Pengetahuan }) => ({ default: Pengetahuan })))
const Forum = lazy(() => import('../pages/forum/Forum').then(({ Forum }) => ({ default: Forum })))
const Profil = lazy(() => import('../pages/profil/Profil').then(({ Profil }) => ({ default: Profil })))
const JadwalDiklat = lazy(() => import('../pages/jadwal_diklat/JadwalDiklat').then(({ JadwalDiklat }) => ({ default: JadwalDiklat })))
const AktivitasMateri = lazy(() => import('../pages/jadwal_diklat/materi/AktivitasMateri').then(({ AktivitasMateri }) => ({ default: AktivitasMateri })))
const AktivitasUjian = lazy(() => import('../pages/jadwal_diklat/ujian/AktivitasUjian').then(({ AktivitasUjian }) => ({ default: AktivitasUjian })))
const UjianWrapper = lazy(() => import('../pages/jadwal_diklat/ujian/UjianWrapper').then(({ UjianWrapper }) => ({ default: UjianWrapper })))

const routes = [
  { path: '/beranda', key: 'beranda', exact: false, name: 'Beranda', element: <Beranda /> },
  { path: '/diklat', key: 'diklat', exact: false, name: 'Diklat', element: <Diklat /> },
  { path: '/diklat/detail', key: 'diklat detail', exact: false, name: 'DiklatDetail', element: <DiklatDetail /> },
  { path: '/agenda', key: 'agenda', exact: false, name: 'Agenda', element: <Agenda /> },
  { path: '/pengetahuan', key: 'pengetahuan', exact: false, name: 'Pengetahuan', element: <Pengetahuan /> },
  { path: '/forum', key: 'forum', exact: false, name: 'Forum', element: <Forum /> },
  { path: '/profil', key: 'profil', exact: false, name: 'Profil', element: <Profil /> },
  { path: '/jadwal-diklat', key: 'jadwal diklat', exact: false, name: 'JadwalDiklat', element: <JadwalDiklat /> },
  { path: '/aktivitas-materi', key: 'aktivitas materi', exact: false, name: 'AktivitasMateri', element: <AktivitasMateri /> },
  { path: '/aktivitas-ujian', key: 'aktivitas ujian', exact: false, name: 'AktivitasUjian', element: <AktivitasUjian /> },
  { path: '/ujian', key: 'ujian', exact: false, name: 'Ujian', element: <UjianWrapper /> },
];

export default routes;
