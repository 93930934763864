// import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Navbar } from './Navbar';
import { useAuth } from '../utils/Auth';
import { PilihRole } from '../pages/auth/PilihRole';

const MasterLayout = () => {
  const location = useLocation();
  const { role } = useAuth();

  return (
    <div>
      {!role.selected ?
          <PilihRole /> :
          <>
            {!location.pathname.includes("/ujian/") && <Navbar />}
            <div className='bodyContent'>
              <Outlet />
            </div>
          </>
      }
    </div>
  );
}

export default MasterLayout;
