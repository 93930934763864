import React, { useState, useEffect } from 'react'
import { toAbsoluteUrl } from '../helpers'

const DelayedFallback = () => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    let timeout = setTimeout(() => setShow(true), 300)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <>
      {show &&
        <div className="position-absolute top-50 start-50">
          <img src={toAbsoluteUrl('/media/images/logo-ihc.png')} alt='Start logo' height={50} />
          <span>Loading ...</span>
        </div>
      }
    </>
  )
}
export default DelayedFallback