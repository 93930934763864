import { useEffect, useRef, useState } from "react";
import { getUserByToken } from "../store/AuthCRUD";
import { setUserToken, useAuth } from "../utils/Auth";
import { useLocation } from "react-router-dom";
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import package_json from '../../package.json'

// const LayoutSplashScreen = () => {
//   <span>Loading ...</span>
// }

const AuthInit = (props:any) => {
  const location = useLocation();
  const urlLogout = location.pathname.includes('logout')
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const { onLogout, accessToken } = useAuth();
  
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken()
          if (data.data?.version !== package_json.version) {
            const arr_ver_api = data.data?.version.split(".")
            const arr_ver_client = package_json.version.split(".")
            let err_status = false
            if (Number(arr_ver_api[0]) > Number(arr_ver_client[0])) {
              err_status = true
            }
            else {
              if (Number(arr_ver_api[1]) > Number(arr_ver_client[1])) {
                err_status = true
              }
              else {
                if (Number(arr_ver_api[2]) > Number(arr_ver_client[2])) {
                  err_status = true
                }
              }
            }
            // if (err_status) toast.error("Versi belum terupdate, mohon melakukan hard reload (ctrl + f5) !", {position: toast.POSITION.TOP_RIGHT, autoClose: false});
            if (err_status) {
              if('caches' in window){
                console.log('test')
                caches.keys().then((names) => {
                  // Delete all the cache files
                  names.forEach(name => {
                    caches.delete(name);
                  })
                });
          
                // Makes sure the page reloads. Changes are only visible after you refresh.
                window.location.reload();
              }
              localStorage.clear();
            }
          }

          setUserToken(data.data.token)
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          onLogout()
        }
      } finally {
        setShowSplashScreen(false)
      }
      return () => (didRequest.current = true)
    }

    // async function handleRefreshClick () {
    //   navigator.serviceWorker.getRegistrations().then((registrations) => {
    //     registrations.forEach((registration) => {
    //       registration.unregister();
    //     });
    //   });
    //   caches.keys().then((keyList) => {
    //     return Promise.all(
    //       keyList.map((key) => {
    //         return caches.delete(key);
    //       })
    //     );
    //   });
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 1000);
    // }

    if (!urlLogout) {
      if (accessToken) {
        requestUser()
      }
    }
    setShowSplashScreen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return showSplashScreen ? <span>Loading ...</span> : <>{props.children}</>
}

export default AuthInit
