import { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../helpers"
import { useAuth } from "../utils/Auth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const { onLogout } = useAuth();
  const location = useLocation();
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const [menuActive, setMenuActive] = useState<string>('');

  const handleLogout = () => {
    setFormDisabled(true)
    toast.success("Logout Sukses !", {position: toast.POSITION.TOP_RIGHT});
    setTimeout(() => {
      setFormDisabled(false)
      onLogout()
    }, 2000);
  }

  useEffect(() => {
    const arr_path = location.pathname.split('/')
    setMenuActive(arr_path[1])
  }, [location.pathname, menuActive])

  return (
    <div className="w-100" style={{position: 'fixed', zIndex: 3}}>
      <nav className="navbar navbar-expand-lg bg-light p-0 m-0 border-bottom border-2">
        <div className="container-fluid ps-0">
          <Link className="navbar-brand py-0" to='/profil'>
            <img
              alt='header-img'
              // className='h-60px logo'
              src={window.innerWidth > 1024 ? toAbsoluteUrl('/media/images/logo3.png') : toAbsoluteUrl('/media/images/logo4.png')}
            />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <div className="me-auto"></div>
            <ul className="navbar-nav mb-2 mb-lg-0 ms-auto">
              {/* <li className="nav-item mx-2 d-flex">
                <Link className={`nav-link ${menuActive === 'beranda' && 'active'}`} to='/beranda'>
                  <span>Beranda</span>
                </Link>
              </li> */}
              <li className="nav-item mx-2 d-flex">
                <Link className={`nav-link ${menuActive === 'diklat' && 'active'}`} to='/diklat'>
                  <span>Diklat</span>
                </Link>
              </li>
              {/* <li className="nav-item mx-2 d-flex">
                <Link className={`nav-link ${menuActive === 'agenda' && 'active'}`} to='/agenda'>
                  <span>Agenda</span>
                </Link>
              </li> */}
              {/* <li className="nav-item mx-2 d-flex">
                <Link className={`nav-link ${menuActive === 'pengetahuan' && 'active'}`} to='/pengetahuan'>
                  <span>Pengetahuan</span>
                </Link>
              </li>
              <li className="nav-item mx-2 d-flex">
                <Link className={`nav-link ${menuActive === 'forum' && 'active'}`} to='/forum'>
                  <span>Forum</span>
                </Link>
              </li> */}
              <li className="nav-item mx-2 d-flex">
                <span className="nav-link dropdown-toggle" id="dropdownMenuClickableInside" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{color: '#9d9d9d'}}>
                  Akun
                </span>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuClickableInside">
                  <li>
                    <Link className="dropdown-item" to='/profil'>
                      <span>Profil</span>
                    </Link>
                  </li>
                  <li>
                    <button type="button" disabled={formDisabled} className="dropdown-item btn btn-link" onClick={handleLogout}>
                      {formDisabled && <i className="fas fa-spinner fa-pulse"></i>} Logout
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export {Navbar}
