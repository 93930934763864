import { useContext } from 'react';
import AuthContext from '../context/AuthContext';

export const useAuth = () => useContext(AuthContext);

export const setUserData = (user) => {
  if (!(user instanceof Object)) {
    throw new Error('No valid data found');
  }
  if (Object.keys(user).length === 0) {
    throw new Error('No data found');
  }
  localStorage.setItem('user', JSON.stringify(user));
};

export const setUserRole = (role) => {
  if (!(role instanceof Object)) {
    throw new Error('No valid data found');
  }
  if (Object.keys(role).length === 0) {
    throw new Error('No data found');
  }
  localStorage.setItem('role', JSON.stringify(role));
};

export const setUserDivisi = (divisi) => {
  if (!(divisi instanceof Object)) {
    throw new Error('No valid data found');
  }
  if (Object.keys(divisi).length === 0) {
    throw new Error('No data found');
  }
  localStorage.setItem('divisi', JSON.stringify(divisi));
};

export const setUserToken = (token) => {
  if (token === undefined || token === null) {
    throw new Error('No valid data found');
  }
  if (token.length === 0) {
    throw new Error('No data found');
  }
  localStorage.setItem('accessToken', token);
};

export const getUserData = () => {
  return JSON.parse(localStorage.getItem('user') || '{}');
};

export const getUserRole = () => {
  return JSON.parse(localStorage.getItem('role') || '{}');
};

export const getUserDivisi = () => {
  return JSON.parse(localStorage.getItem('divisi') || '{}');
};

export const getAccessToken = () => {
  return localStorage.getItem('accessToken') || null;
};

export function clearData() {
  localStorage.removeItem('user');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('role');
  localStorage.removeItem('divisi');
}

export const isAuthenticated = () => {
  const accessToken = getAccessToken();
  if (!accessToken) return false;
  return true;
};