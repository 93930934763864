import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../utils/Auth";

const RequireAuth = () => {
  const { user }:any = useAuth();
  const isLogin = Object.values(user).length !== 0 && user.constructor === Object
  const location = useLocation();

  return (
    isLogin
      ? <Outlet />
      : <Navigate to="/login" state={{ from: location }} replace />
  );
}

export default RequireAuth;