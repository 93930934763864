import { createContext } from "react";

const AuthContext = createContext({
  user: '',
  role: '',
  divisi: '',
  accessToken: '',
  onLogin: (params) => {},
  onLogout: () => {},
});

export default AuthContext;