import {useEffect} from 'react'
import { useAuth } from '../utils/Auth';
import { logout } from '../store/AuthCRUD';

export function Logout() {
  const { onLogout } = useAuth();
  useEffect(() => {
    // ini method bisa diganti biar ga double reload
    logout()
      .then(() => {
        onLogout()
      })
      .catch(err => {
        onLogout()
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='vw-100 vh-100' style={{position: 'absolute', zIndex: 99, background: 'white', top: 0}}>
      <span>Loading ...</span>
    </div>
  )
}
