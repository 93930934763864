import axios from '../config/axiosInterceptor'
// import { generateRandom } from '../helpers/generateRandom'
export const GET_USER_BY_ACCESSTOKEN_URL = `user/verify-iknow`
export const LOGIN_URL = `user/login-iknow`
export const LOGOUT_URL = `user/logout`

// Server should return AuthModel
export function login(userid: string, password: string) {
  // return axios.post(LOGIN_URL, {userid, password})
  return new Promise((resolve, reject) => {
    axios.post(LOGIN_URL, {userid, password})
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        console.log(error)
        reject(error.response);
      });
    // if (userid === 'admin' && password === 'admin') {
    //   const response = {data: {user: {userid}, token: generateRandom() + generateRandom()}}
    //   resolve(response)
    // }
    // else if (userid === 'userid' && password === 'password') {
    //   const response = {data: {user: {userid}, token: generateRandom() + generateRandom()}}
    //   resolve(response)
    // }
    // else {
    //   reject({});
    // }
  })
}

export function logout() {
  const config = {
    headers:{
      Authorization: `Bearer ` + localStorage.token
    }
  }
  return axios.post(LOGOUT_URL, config)
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  // return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
  // const param = {
  //   application_type: JSON.parse(localStorage.aplikasi)[0].app_application_id
  // }

  return axios.get(GET_USER_BY_ACCESSTOKEN_URL);
}
