// import {
//     useLocation,
//     useNavigate } from 'react-router-dom';
import AuthContext from './AuthContext';
import {
  clearData,
  getAccessToken,
  getUserData,
  getUserDivisi,
  getUserRole,
  setUserData,
  setUserRole,
  setUserToken,
  setUserDivisi,
} from '../utils/Auth';
import { useMemo } from 'react';
// import { logout } from '../store/AuthApi';
  
export const AuthProvider = ({ children }) => {
    const localAccessToken = getAccessToken() || null;
    const userData = getUserData() || null;
    const roleData = getUserRole() || null;
    const divisiData = getUserDivisi() || null;
    // const navigate = useNavigate();
    // const location = useLocation();
    // const [isFirstMounted, setIsFirstMounted] = useState(true);
  
    // const [auth, setAuth] = useState({});

    const handleLogin = (data) => {
        setUserData(data?.user);
        setUserRole(data?.role);
        setUserToken(data?.token);
        setUserDivisi(data?.divisi);
        // const origin = location.state?.from?.pathname || '/';
        // navigate(origin);
        window.location.reload();
    };

    const handleVerify = async (data) => {
        // console.log('succes verifying token')
        // console.log(userData)
        // clearUserData();
        // await logout()
        // window.location.reload();
    };

    const handleLogout = async () => {
        clearData();
        window.location.reload();
    };

    const value = useMemo(() => ({
        user: userData,
        role: roleData,
        divisi: divisiData,
        accessToken: localAccessToken,
        onVerify: handleVerify,
        onLogin: handleLogin,
        onLogout: handleLogout,
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [localAccessToken]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;